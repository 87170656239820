import React, { Component } from 'react';
import { Button, Input, Form } from 'reactstrap';
import { FaTwitter } from 'react-icons/fa';
import './style.css';

class Sidebar extends Component {

  startTrial = () => {
    let email = document.getElementById("start_email").value;
    window.open(
      `https://www.accelerlist.com/register?email=${email}`,
      '_blank'
    ).focus();
  };

  render() {
    const { tweet_data } = this.props;

    return (
      <div className="sidebar">
        <div className="sidebar-widget">
          <div className="mb-4">
            <p className="mb-0">by AccelerList</p>
            <a href="https://www.accelerlist.com/">www.accelerlist.com</a>
          </div>
          <a
            className="btn btn-primary btn-sm btn-block"
            href="https://twitter.com/AccelerList"
            size="sm"
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="mr-2" />
            <span>Follow @accelerlist</span>
          </a>
          <a
            className="btn btn-primary btn-sm btn-block twitter-share-button"
            href={`https://twitter.com/intent/tweet?text=${tweet_data.text}&url=${tweet_data.url}`}
            size="sm"
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="mr-2" />
            <span>Tweet this episode</span>
          </a>
        </div>
        <div className="sidebar-widget widget-cta">
          <div className="text">
            <span>START A 14 DAY</span>
            <span className="big">FREE</span>
            <span>TRIAL TODAY!</span>
          </div>
          <Form
            onSubmit={e => this.startTrial()}
          >
            <Input
              type="email"
              placeholder="Enter email"
              id="start_email"
            />
            <Button
              color="primary"
              block
            >START TRIAL</Button>
          </Form>
        </div>
      </div>
    )
  }
}

export default Sidebar;
