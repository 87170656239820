import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col, Card, CardImg, CardBody, CardTitle } from 'reactstrap';
import {
  podcastApple, podcastOvercast, podcastPocketCasts,
  podcastSpotify, homeHeaderImg, podcastImg
} from '../../../assets/images';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy, FaLongArrowAltRight } from 'react-icons/fa';
import './style.css';

class Home extends Component {
  state = {
    transistorUrl: 'https://share.transistor.fm/s/481680a9',
    copied: false
  }

  render() {
    return (
      <div className="home">
        <Container>
          <div className="home-header">
            <h1 className="sr-only">The Sellers Corner</h1>
            <img src={homeHeaderImg} alt="" className="w-100" />
          </div>
          <Row>
            <Col md={8}>
              <div className="home-episodes">
                <Row>
                  <Col lg={6}>
                    <Card className="card-episode">
                      <Link to="/episode?id=4">
                        <CardImg top width="100%" src={podcastImg} alt="" />
                      </Link>
                      <CardBody>
                        <Link to="/episode?id=4">
                          <CardTitle tag="h2">Successful Online Seller Who's Motivating the World on TikTok</CardTitle>
                        </Link>
                        <p className="brief">In this episode, we talk to William aka The Money Badger who has been selling online for a few years...</p>
                        <Link to="/episode?id=i4" className="link">Listen to episode <FaLongArrowAltRight /></Link>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={6}>
                    <Card className="card-episode">
                      <Link to="/episode?id=3">
                        <CardImg top width="100%" src={podcastImg} alt="" />
                      </Link>
                      <CardBody>
                        <Link to="/episode?id=3">
                          <CardTitle tag="h2">Lost His Job but Now Sells Video Games Online</CardTitle>
                        </Link>
                        <p className="brief">In this episode, we talk to Caleb Ashton who lost his job and made the choice to sell video games...</p>
                        <Link to="/episode?id=3" className="link">Listen to episode <FaLongArrowAltRight /></Link>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Card className="card-episode">
                      <Link to="/episode?id=2">
                        <CardImg top width="100%" src={podcastImg} alt="" />
                      </Link>
                      <CardBody>
                        <Link to="/episode?id=2">
                          <CardTitle tag="h2">He Quit His Full Time Job to Sell Books on Amazon</CardTitle>
                        </Link>
                        <p className="brief">In this episode we talk to John Muscarello who quit his full time job to sell books on Amazon.
                        He shares how...</p>
                        <Link to="/episode?id=2" className="link">Listen to episode <FaLongArrowAltRight /></Link>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={6}>
                    <Card className="card-episode">
                      <Link to="/episode?id=1">
                        <CardImg top width="100%" src={podcastImg} alt="" />
                      </Link>
                      <CardBody>
                        <Link to="/episode?id=1">
                          <CardTitle tag="h2">She survived a truck collision and now sells online full time</CardTitle>
                        </Link>
                        <p className="brief">This episode we talked to Karin Weizel about her decision to become an amazon seller after...</p>
                        <Link to="/episode?id=1" className="link">Listen to episode <FaLongArrowAltRight /></Link>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={6}>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <p>Welcome to the Seller's Corner. An eCommerce podcast with real stories from real sellers. Each episode we sit down with online
                sellers all across the globe and talk about overcoming challenges, scaling and putting processes around their online businesses.
                <br/>
                Welcome to the show!
              </p>
              <h2 className="h3 text-center mb-3">Join 7,000+ subscribers</h2>
              <div className="podcast-providers">
                <a href="https://open.spotify.com/show/6hxoTxjkLbR2uJgKpVPYIC" target="_blank" rel="noopener noreferrer">
                  <img src={podcastSpotify} alt="Spotify" />
                </a>
                <a href="#link" target="_blank" rel="noopener noreferrer">
                  <img src={podcastOvercast} alt="Overcast" />
                </a>
                <a href="#link" target="_blank" rel="noopener noreferrer">
                  <img src={podcastApple} alt="Apple" />
                </a>
                <a href="https://pca.st/s2h5k838" target="_blank" rel="noopener noreferrer">
                  <img src={podcastPocketCasts} alt="Pocket Casts" />
                </a>
              </div>
              <div className="podcast-url text-center">
                <p className="lead mt-3">or use this URL in your favorite podcast app:</p>
                <span className="url d-block mb-2">{this.state.transistorUrl}</span>
                <CopyToClipboard
                  text={this.state.transistorUrl}
                  onCopy={() => this.setState({copied: true})}
                >
                  <Button color="link" className="btn-copy" title="Copy URL"><FaRegCopy /> Copy URL</Button>
                </CopyToClipboard>
                <span className="status">{this.state.copied ? "Copied!" : "\u00A0" }</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Home;
