import React from 'react';
import './App.css';
import AppRoutes from './routes';
import createHistory from 'history/createBrowserHistory';

const history = createHistory();
function App() {
  return (
    <AppRoutes history={history} />
  );
}

export default App;