import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import './style.css';

class PageHeader extends Component {
  render() {
    return (
      <div className="page-header">
        <Container>
          <h1 className="page-title"><Link to="/">The Sellers Corner</Link></h1>
        </Container>
      </div>
    )
  }
}

export default PageHeader;