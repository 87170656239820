import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import PageHeader from '../../../shared/components/PageHeader';
import Sidebar from '../../../shared/components/Sidebar';

const episodes_data = {
  "1": {
    src: "https://share.transistor.fm/e/75f1d0e2",
    summary: "This episode we talked to Karin Weizel about her decision to become an amazon seller after a terrible accident at work. She goes over some of the resources and tips she used to start her seller journey.",
    timeline: [
      {
        time: "[0:50]",
        text: "Accident that changed everything",
      },
      {
        time: "[1:40]",
        text: "What Karin Sells Online",
      },
      {
        time: "[2:50]",
        text: "Why Karin Started Selling on Amazon",
      },
      {
        time: "[6:23]",
        text: "What she wishes she had known before starting",
      },
      {
        time: "[9:46]",
        text: "Best & Worst aspects on selling online",
      },
      {
        time: "[12:55]",
        text: "Resources Karin used to launch her business",
      },
      {
        time: "[15:43]",
        text: "Used Tik Tok as inspiration and a resource",
      },
      {
        time: "[17:25]",
        text: "Debunk an assumption about selling on Amazon",
      },
      {
        time: "[19:14]",
        text: "Advice for starting your seller journey",
      },
      {
        time: "[20:18]",
        text: "Where does she see herself in 5 years?",
      },
      {
        time: "[22:05]",
        text: "Is the seller community helpful?",
      },
    ],
    additional: (
      <React.Fragment>
        <section>
          <b>Resources Mentioned:</b><br />
          <ul>
            <li>Tik Tok</li>
            <li>Book: <a href="https://www.amazon.com/Think-Grow-Rich-Publication-Foundation/dp/193787950X/ref=pd_lpo_14_img_0/145-6513840-8864067?_encoding=UTF8&pd_rd_i=193787950X&pd_rd_r=a40d198f-5536-4f1b-9e37-26b682de9c8d&pd_rd_w=cpaZq&pd_rd_wg=n2ctu&pf_rd_p=7b36d496-f366-4631-94d3-61b87b52511b&pf_rd_r=H772J8D5K9BKCVS11GE5&psc=1&refRID=H772J8D5K9BKCVS11GE5"> “Think and Grow Rich” by Napoleon Hill</a></li>
          </ul>
        </section>
        <section>
          <b>Seller Tips:</b><br />
          Consume content and research a lot before launching into selling on Amazon.
        </section>
      </React.Fragment>
    ),
    tweet_data: {
      text: "This is awesome!!!",
      url:"https://podcast.accelerlist.com",
    }
  },
  "2": {
    src: "https://share.transistor.fm/e/7ad0d7e8",
    summary: "In this episode we talk to John Muscarello who quit his full time job to sell books on Amazon. He shares how he originally started selling furniture locally and then decided to switch to selling on Amazon and eBay. He tells us how his business is growing, and actionable steps you can take to do the same!",
    timeline: [
      {
        time: "[0:58]",
        text: "How John got started selling online",
      },
      {
        time: "[2:06]",
        text: "How did he feel about quitting his full time job",
      },
      {
        time: "[3:06]",
        text: "Moment of Panic about leaving his job",
      },
      {
        time: "[4:22]",
        text: "What a typical work day looks like now as an Amazon seller",
      },
      {
        time: "[6:08]",
        text: "Scaling his business quickly",
      },
      {
        time: "[6:55]",
        text: "How the pandemic affected his business",
      },
      {
        time: "[11:06]",
        text: "What he wishes he'd known before jumping to selling on Amazon",
      },
      {
        time: "[14:40]",
        text: "Why he started his blog sidehustleexperiment.com",
      },
      {
        time: "[18:40]",
        text: "All the work that happens behind the scenes",
      },
      {
        time: "[23:50]",
        text: "Library Sales",
      },
      {
        time: "[25:25]",
        text: "Bulk Model",
      },
      {
        time: "[26:00]",
        text: "What Book genres sell the most",
      },
      {
        time: "[29:00]",
        text: "Hardest part about being a seller",
      },
      {
        time: "[33:03]",
        text: "How AccelerList has helped his business",
      },
    ],
    additional: (
      <React.Fragment>
        <section>
          <b>Resources Mentioned:</b><br />
          <ul>
            <li>Book: <a href="https://www.amazon.com/Never-Split-Difference-Negotiating-Depended/dp/0062407805/ref=sr_1_1?crid=39OEH2VBHFQKP&dchild=1&keywords=never+split+the+difference&qid=1608639945&sprefix=never+split%2Caps%2C269&sr=8-1"> Never Split the Difference by Chris Voss</a></li>
            <li>Book: <a href="https://www.amazon.com/Ego-Enemy-Ryan-Holiday/dp/1591847818/ref=sr_1_6?dchild=1&keywords=ryan+holiday&qid=1608640037&sr=8-6"> Ego is the Enemy by Ryan Holiday</a></li>
          </ul>
        </section>
        <section>
          <b>Seller Tips:</b><br />
          <p>Bulk model might be best if you don't have thrift stores nearby or you don't enjoy going to library sales.</p>
          <p>Build sets of books, especially of children's books before putting them up for sale as individual books</p>
        </section>
      </React.Fragment>
    ),
    tweet_data: {
      text: "This is awesome!!!",
      url:"https://podcast.accelerlist.com",
    }
  },
  "3": {
    src: "https://share.transistor.fm/e/3189732c",
    summary: "In this episode, we talk to Caleb Ashton who lost his job and made the choice to sell video games online while his wife goes to law school. He shares tips and tricks and how you can get started with selling video games on Amazon or eBay.",
    timeline: [
      {
        time: "[0:38]",
        text: "When Caleb started selling online",
      },
      {
        time: "[2:11]",
        text: "A typical workday",
      },
      {
        time: "[5:18]",
        text: "Shifting gears when sources dried up",
      },
      {
        time: "[7:30]",
        text: "What he wishes he had known before starting",
      },
      {
        time: "[12:10]",
        text: "Lost his job while selling on the side",
      },
      {
        time: "[17:22]",
        text: "Trends in video game reselling",
      },
      {
        time: "[20:03]",
        text: "Great places to find games",
      },
      {
        time: "[27:04]",
        text: "A failure he learned from",
      },
      {
        time: "[36:10]",
        text: "Top Five Favorite games",
      },
      {
        time: "[39:11]",
        text: "Some final advice",
      },
    ],
    additional: (
      <React.Fragment>
        <section>
          <b>Resources Mentioned:</b><br />
          <ul>
            <li>His YouTube Channel <a href="https://www.youtube.com/channel/UCYWd5Q-C8M-OeDzKo86CAeg"> Phoenix Resale</a></li>
          </ul>
        </section>
        <section>
          <b>Seller Tips:</b><br />
          <p>Go to pawn shops where you might find that a lot of games are underpriced. </p>
          <p>Don't compare your seller journey to someone else's carefully curated Instagram feed</p>
        </section>
      </React.Fragment>
    ),
    tweet_data: {
      text: "This is awesome!!!",
      url:"https://podcast.accelerlist.com",
    }
  },
  "4": {
    src: "https://share.transistor.fm/e/953e33e0",
    summary: "In this episode, we talk to William aka The Money Badger who has been selling online for a few years and garnered a large following on TikTok with his tips, tricks, and motivational words. Learn how he got started and why he wants to help you on your journey as well.",
    date: "February 17, 2021",
    timeline: [
      {
        time: "[1:03]",
        text: "Why he started selling online",
      },
      {
        time: "[4:22]",
        text: "Why he gives tips & info about selling for free",
      },
      {
        time: "[6:15]",
        text: "Motivates everyone not just the seller community through TikTok",
      },
      {
        time: "[7:37]",
        text: "His Facebook community ",
      },
      {
        time: "[9:40]",
        text: "The Effect of the pandemic on the seller community",
      },
      {
        time: "[12:23]",
        text: "Typical workday",
      },
      {
        time: "[14:33]",
        text: "Pivot your business",
      },
      {
        time: "[18:26]",
        text: "A failure he learned from",
      },
      {
        time: "[21:32]",
        text: "Imposter Syndrome",
      },
      {
        time: "[24:40]",
        text: "Bought a one-way ticket to the Netherlands",
      },
      {
        time: "[29:32]",
        text: "Tips & Tricks to start selling online",
      },
    ],
    additional: (
      <React.Fragment>
        <section>
          <b>Resources Mentioned:</b><br />
          <ul>
            <li>His website <a href="https://iamthemoneybadger.com"> Money Badger</a></li>
          </ul>
        </section>
        <section>
          <b>Seller Tips:</b><br />
          <p>Pivot your business to sell outside of just used books</p>
          <p>Look through your house to find some things to start selling online</p>
        </section>
      </React.Fragment>
    ),
    tweet_data: {
      text: "This is awesome!!!",
      url:"https://podcast.accelerlist.com",
    }
  },
}

class Episode extends Component {

  parseEpisodeID = () => {
    const { location } = this.props;
    if(!location || !location.search){
      return episodes_data['1'];
    }
    let id = location.search.split('=');
    id = id[id.length - 1];
    if(!Object.keys(episodes_data).includes(id)){
      return episodes_data['1'];
    }
    return episodes_data[id];
  }

  render() {

    const data = this.parseEpisodeID()

    return (
      <div className="page episode">
        <PageHeader />
        <div className="page-content">
          <Container>
            <div className="podcast-embed">
              <iframe
                title="Podcast Embed"
                width="100%"
                height="180"
                frameBorder="no"
                scrolling="no"
                seamless
                src={data.src}
              ></iframe>
            </div>
            <Row>
              <Col md={3}>
                <Sidebar
                  tweet_data={data.tweet_data}
                />
              </Col>
              <Col md={9}>
                <section>
                  <h2 className="section-title">SUMMARY</h2>
                  <p>{data.summary}</p>
                </section>
                <section>
                  <h2 className="section-title">SHOW NOTES</h2>
                  <p>Conversation Timestamps</p>
                  <ul className="list-unstyled conversation-timestamps">
                    {data.timeline.map((item, idx) => (
                      <li key={`teimeline-${idx}`}>
                        <span className="timestamp">{item.time}</span> {item.text}
                      </li>
                    ))}
                  </ul>
                </section>
                {data.additional && data.additional }
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}

export default Episode;
