import React, { Component } from 'react';
import { Router, Route, Switch  } from 'react-router';

import Home from './views/front/Home';
import Episode from './views/front/Episode';

class TemplateFront extends Component {
  render() {
    const {
      children
    } = this.props;

    return (
      <div className="app app-front">
        {children}
      </div>
    )
  }
}

// class TemplateAdmin extends Component {
//   render() {
//     const {
//       children
//     } = this.props;

//     return (
//       <div className="app app-admin">
//         {children}
//       </div>
//     )
//   }
// }

// const RouteAdmin = ({ component: Component, isLoggedIn, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={ matchProps => (
//         isLoggedIn ? (
//           <TemplateAdmin>
//             <Component {...matchProps} />
//           </TemplateAdmin>
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/",
//               state: { from: matchProps.location }
//             }}
//           />
//         )
//       )}
//     />
//   )
// }

class AppRoutes extends Component {
  render() {
    const { 
      history 
    } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" render= {
            props => (
              <TemplateFront>
                <Home {...props} />
              </TemplateFront>
            )}
          />
          <Route exact path="/episode" render= {
            props => (
              <TemplateFront>
                <Episode {...props} />
              </TemplateFront>
            )}
          />
        </Switch>
      </Router>
    )
  }
}

export default AppRoutes;

